import * as _ from 'lodash';

export default _.merge({
    appInfo: window.appInfo || {}, // from index.html

    api: {
        prefix: '/api',
        timeout: 30000, // 30 seconds max wait time for an API call
        warningAfter: 5000
    },

    applicationInsights: window.applicationInsights ||
    {
        key: '',
        enabled: false
    },

    alertDuration: 5000,
    dispatchAlertDuration: 8000,
    pastDueBalanceAlertDuration: 10000,
    passcodeProhibitedAlertDuration: 10000,
    serviceAddOns: {
        IdentityProtection: {
            Name: 'Identity Protection',
            Id: 1,
            Url: 'https://portal.allstateidentityprotection.com/enroll/sign-up?partner=frontpoint&__hstc=94693315.111951f55c34b9ecd0d6b93a7c5dbf50.1623443166416.1632244676984.1632251966504.24&__hssc=94693315.7.1632251966504&__hsfp=3488606808&_ga=2.257368525.776290906.1632244676-1904148463.1623443166'
        }
    },
    beta: {
        shop: {
            settingKey: 'CustomerPortal:Beta',
            platforms: ['ua-desktop']
        },

        eComm: {
            settingKey: 'CustomerPortal:EcommerceInfoModalBeta'
        },

        addEquipment: {
            settingKey: 'CustomerPortal:AddEquipmentTab',
            platforms: ['ua-mobile']
        }
    },

    alerts: {
        expiredCard: {
            settingKey: 'CustomerPortal:ExpiredCreditCard'
        },
        pastDuePayment: {
            enabled: true
        }
    },

    urls: {
        contact: 'https://www.frontpointsecurity.com/contact',
        support: 'https://support.frontpointsecurity.com/hc/en-us',
        setupWizard: 'https://activate.frontpointsecurity.com'
    },

    suppressAnalyticsSettingKey: 'SuppressAnalytics',

    cacheRefreshInterval: 5 * 60 * 1000, // 5 minutes

    debug: false,

    inputDebounce: 500, // .5 secs

    customerSupport: {
        formattedPhone: '+1 (877) 602-5276',
        phone: '18776025276',
        callback: {
            timeIncrement: 15,
            timeZone: 'local'
        },
        jciFormattedPhone: '+1 (855) 612-1818',
        orlandoAccountsPhone: '+1 (844) 344-7888'
    },

    phoneNumbers: {
        falseAlarm: '18009323822',
        support: '18776025276',
        sales: '18558081480'
    },

    storage: {
        prefix: 'fp',
        accessTokenKey: 'accessToken',
        homePagePreferenceKey: 'prefs:homePage',
        changeNoticeAcknowledged: 'changeNoticeAcknowledged',
        fromApp: 'fromApp'
    },

    events: {
        completeCancellation: 'completeCancellation',
        processCreditCardPayment: 'processCreditCardPayment',
        stateChangeSuccess: '$stateChangeSuccess',
        stateChangeError: '$stateChangeError',
        stateNotFound: '$stateNotFound',
        stateChangeStart: '$stateChangeStart',
        viewContentLoading: '$viewContentLoading',
        viewContentLoaded: '$viewContentLoaded',

        modalOpened: 'modal-opened',
        modalClosed: 'modal-closed',

        login: 'event:login',
        loginRequired: 'event:auth-loginRequired',
        logout: 'event:logout',

        showAlert: 'event:show-alert',
        showWithAlert: 'event:show-with-alert',
        hideAlert: 'event:hide-alert',

        idleStart: 'IdleStart',
        idleEnd: 'IdleEnd',
        idleWarn: 'IdleWarn',
        idleTimeout: 'IdleTimeout',
        keepalive: 'Keepalive',

        showMenu: 'ShowMenu',
        hideMenu: 'HideMenu',
        refreshMenu: 'RefreshMenu',

        data: {
            userInfoReceived: 'event:data-userInfoReceived',
            customerInfoReceived: 'event:data-customerInfoReceived',
            customerInfoSummaryReceived: 'event:data-customerInfoSummaryReceived',
            monitoringInfoReceived: 'event:data-monitoringInfoReceived',
            paymentInfoReceived: 'event:data-paymentInfoReceived',
            contactInfoUpdated: 'event:data-contactInfoUpdated',
            shippingAddressUpdated: 'event:data-shippingAddressUpdated',
            billingAddressUpdated: 'event:data-billingAddressUpdated',
            premisesAddressUpdated: 'event:data-premisesAddressUpdated',
            accountSummaryReceived: 'event:data-accountSummaryReceived',
            emergencyContactsUpdated: 'event:data-emergencyContactsUpdated'
        },

        refresh: {
            customer: 'event:refresh-customer',
            monitoring: 'event:refresh-monitoring',
            payment: 'event:refresh-payment'
        },

        mobile: {
            contextChanged: 'event:mobile-context-changed'
        },

        iframe: {
            youtube: 'youTubeStateChange'
        },

        offer: {
            confirm: 'offerConfirm'
        },

        dispatch: {
            retrieveUserDispatchHistory: 'retrieveUserDispatchHistory',
            processDispatchPayment: 'processDispatchPayment',
            finalizePaidDispatch: 'finalizePaidDispatch',
            failedPaidDispatch: 'failedPaidDispatch'
        }
    },

    idle: {
        timeout: 1200,
        warning: 60,
        keepalive: 900,
        extendedTimeout: 2700
    },

    cookies: {
        FPSSO: 'FPTOKEN'
    },

    fpRegex: {
        allSpecialCharacters: /[^\w\s]/gi,
        name: /^[a-z ,.'-]+$/i,
        email: /^[A-Za-z0-9](([_\.\-\+]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$/,
        accountNumber: /^\s*((\d{5,19})|(\*{12}\d{4}))\s*$/,
        routingNumber: /^\s*(\d{9})\s*$/,
        creditCardNumber: /^\s*((?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))-?\d{4}-?\d{4}-?\d{4}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})|(?:\*{12}\d{4}))\s*$/,
        decimal: /^[0-9]{1,2}(\.[0-9]{1,2})?$/,
        address: /^[a-z A-Z 0-9.',#&\s-]+$/,
        pobox: /(?:P(?:ost(?:al)?)?[\.\-\s]*(?:(?:O(?:ffice)?[\.\-\s]*)?B(?:ox|in|\b|\d)|o(?:ffice|\b)(?:[-\s]*\d)|code)|box[-\s\b]*\d)|(p\.o)/im,
        zipForUsaAndCanada: /^(\d{5}-\d{4}|\d{5}|\d{6}|\d{9})$|^([a-zA-Z]\d[a-zA-Z]( )?\d[a-zA-Z]\d)$/,
        mastercard: /^5[1-5][0-9]{14}|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))-?\d{4}-?\d{4}-?\d{4}/,
        visa: /^4/,
        amex: /^3[47]/,
        discover: /^6011|65|64[4-9]|622(1(2[6-9]|[3-9]\d)|[2-8]\d{2}|9([01]\d|2[0-5]))/,
        passcode: /^[a-z0-9_]+$/i,
        // USA or Canadian phone number
        // https://www.safaribooksonline.com/library/view/regular-expressions-cookbook/9781449327453/
        twoIntegers: /^[0-9]{1,2}$/,
        otherPetInput: /^.{0,20}$/,
        phoneNumber: /^(?:\+?1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$/,
        password: /^(?=.*\d)(?=.*[a-zA-Z]).{7,15}$/,
        segmentExcludedRoutes: /^((?!movePortal).)*$/,
        permitNumber: /^[A-Z0-9-\.]+$/i
    },

    validationSuccessDesc: 'AddressVerified',

    actionTypes: {
        dataChange: 'Data Change',
        stateChange: 'State Change',
        error: 'Error',
        click: 'Click',
        login: 'Login',
        other: 'Other'
    },

    sectionTypes: {
        login: 'Login',
        ssoLogin: 'SSO Login',
        appLogin: 'Mobile App Login',
        home: 'Home',
        accountSettings: 'Account Settings',
        emergencySettings: 'Emergency Settings',
        payments: 'Payments',
        documents: 'Documents',
        notifications: 'Notifications',
        logout: 'Logout',
        forgot: 'Forgot',
        training: 'Training',
        cancellation: 'Cancellation',
        trackOrder: 'Track Orders'
    },

    navigationMenuSections: {
        majorSections: {
            setupEquipment: 'Setup',
            account: 'My Account',
            support: 'Support',
            contact: 'Contact Us',
            movePortal: 'Move Portal',
            sensors: 'Sensors'
        },
        subSections: {
            mobileApp: 'Mobile App',
            accountSettings: 'Account Settings',
            emergencySettings: 'Emergency Settings',
            preferences: 'Preferences',
            controlPanelSettings: 'controlPanelSettings',
            referral: 'Refer a Friend',
            documents: 'My Documents',
            sensorStatus: 'Sensor Status'
        }
    },

    dataTypes: {
        customerBillingInfo: 'CustomerBillingInfo',
        contactInfo: 'Contact Info',
        shippingAddress: 'Shipping Address',
        billingAddress: 'Billing Address',
        premisesAddress: 'Premises Address',
        passcode: 'Pass Code',
        preDispatch: 'Pre Dispatches',
        postDispatch: 'Post Dispatches',
        testmode: 'Test mode',
        changePassword: 'Change Password'
    },

    monitoringInfoSection: {
        passcode: 1,
        predispatches: 2,
        postdispatches: 3
    },

    homeState: 'account.settings',
    homeStatePMCustomers: 'account.settings',
    fromAdcAppHomeState: 'myaccount',

    // References CustomerSiteSSOInterfaces, DestinationPageEnum
    ssoRedirectMap: {
        // default
        '': 'account.settings',

        // legacy
        'billinginfo': 'account.settings.billing',
        'billinghistory': 'account.settings.billing',
        'updatepaymentinfo': 'account.settings.billing',
        'makepayment': 'account.settings.billing',
        // ------

        'myaccount': 'account.settings',
        'accountsettings': 'account.settings',
        'accountinfo': 'account.settings.info',
        'accountaddresses': 'account.settings.address',
        'accountbilling': 'account.settings.billing',

        'emergencysettings': 'account.emergency',
        'passcode': 'account.emergency.passcode',
        'predispatchcontacts': 'account.emergency.predispatch',
        'postdispatchcontacts': 'account.emergency.postdispatch',
        'monitoringstatus': 'account.emergency.testmode',

        'documents': 'account.documents',
        'contract': 'account.documents.contract',
        'certificateofinsurance': 'account.documents.insurance',
        'datamanagement': 'account.documents.datamanagement',

        'preferences': 'account.preferences',
        'userlogininfo': 'account.preferences.preferences',
        'notificationsettings': 'account.preferences.notifications',

        'support': 'support.support',
        'contact': 'support.contact',

        'shop': 'shop.home',
        'campaign': 'shop.campaign',

        'setup': 'setup',
        'moveportal': 'moving',
        'unavailable': 'unavailable'
    },

    panels: {
        iq: 'IQPanel',
        xt: 'SimonXt',
        unknown: 'Unknown'
    },

    MovePortal: {
        ShowCoupon: true,
        disableDateRange: 2
    },

    offerHardwareTypeRedirectStates: [
        { adcOfferHardwareType: 19, redirectState: 'shop.home' }
    ],

    enrolledPrograms: {
        rapidAddressVerification: 46
    },
    cache: {
        expiry: 86400 //2 months

    },
    ticket: {
        primaryCategory: ['Permit Inquiry', 'Billing Inquiry', 'Contract Inquiry', 'General Inquiry', 'Troubleshooting (Security & Environmental)'],
        nextAction: 'Reach out to customer',
        map: [
            {
                primary: 'Alarm Registrations / Permits',
                department: 21,
                secondary: 104
            },
            {
                primary: 'Contract Inquiry',
                department: 26,
                secondary: 51
            },
            {
                primary: 'Troubleshooting',
                department: 22,
                secondary: 171
            },
            {
                primary: 'General Inquiry',
                department: 22,
                secondary: 64
            },
            {
                primary: 'Billing Inquiry',
                department: 22,
                secondary: 39
            }
        ]
    },

    adcPlans:
    {
        VideoOnly: 'VideoOnly',
        WSF: 'WSF'
    },

    timeFrameNames: {
        RFTP: "RFTP",
        InTerm: "In-Term",
        EndOfTerm: "End of Term",
        OutOfTerm: "Out Of Term",
        AtTerm: "At Term"
    },

    timeFrames: {
        RFTP: 5,
        InTerm: 7,
        OutOfTerm: 9,
        AtTerm: 10
    },

    accountStatusType: {
        PendingReturn: 8,
        PendingCancel: 9,
        Cancelled: 10,
        Active: 11,
        Returned: 15
    },

    ACHOfferAction: {
        None: 0,
        Creating: 1,
        DelayedCreation: 2,
        Deactivating: 3,
        DelayedDeactivation: 4
    },

    retentionEpisodeType: {
        Save: 1,
        ManualCancel: 2,
        ESR: 3,
        Cancel: 7
    },

    retentionConfig: {
        notUsing: {
            questionNumber: 1,
            RetentionAnswerOptionID: 1,
            offer: {
                RetentionOfferTypeID: 11
            },
            name: 'notUsing'
        },
        moving: {
            questionNumber: 1,
            RetentionAnswerOptionID: 2,
            offer: {
                RetentionOfferTypeID: 11
            },
            name: 'moving'
        },
        couldntInstall: {
            questionNumber: 1,
            RetentionAnswerOptionID: 3,
            offer: {
                RetentionOfferTypeID: 1
            }
        },
        dissatisfaction: {
            questionNumber: 1,
            RetentionAnswerOptionID: 4,
            offer: {
                RetentionOfferTypeID: 11
            },
            name: 'dissatisfaction'
        },
        competitor: {
            questionNumber: 1,
            RetentionAnswerOptionID: 5,
            offer: {
                RetentionOfferTypeID: 11
            },
            name: 'competitor'
        },
        financial: {
            questionNumber: 1,
            RetentionAnswerOptionID: 6,
            offer: {
                RetentionOfferTypeID: 11
            },
            name: 'financial'
        },
        default: {
            questionNumber: 1,
            offer: {
                RetentionOfferTypeID: 3
            },
            name: 'default'
        },
        feedback: {
            questionNumber: 2,
            answerOptions: {
                price: {
                    RetentionAnswerOptionID: 7,
                    Name: 'Price'
                },
                product: {
                    RetentionAnswerOptionID: 8,
                    Name: 'Product'
                },
                contract: {
                    RetentionAnswerOptionID: 9,
                    Name: 'Contract'
                },
                features: {
                    RetentionAnswerOptionID: 10,
                    Name: 'Features'
                },
                customerService: {
                    RetentionAnswerOptionID: 11,
                    Name: 'Customer Service'
                }
            }
        },
        check: {
            questionNumber: 3,
            answerOptions: {
                yes: {
                    RetentionAnswerOptionID: 12,
                    Name: 'Yes'
                },
                no: {
                    RetentionAnswerOptionID: 13,
                    Name: 'No'
                }
            }
        },
        confirmation: {
            questionNumber: 4
        }
    },

    trackOrderConfig: {
        knowledgeBaseUrl: "https://support.frontpointsecurity.com/hc/en-us/articles/4633391640723-How-do-I-track-my-order-"
    },

    syncRapidAddress: false,

    showCCExpiredModal: false,

    radioDeal: {
        couponCode: "FREERADIO12MO",
        couponCode4G: "FREERADIO"
    },

    "CustomMedicalConditions": [
        {
            "ConditionCode": "100",
            "ConditionDescription": "Atrial Fibrillation",
            "ConditionCategory": "Medical"
        },
        {
            "ConditionCode": "101",
            "ConditionDescription": "Parkinson's Disease",
            "ConditionCategory": "Medical"
        },
        {
            "ConditionCode": "102",
            "ConditionDescription": "Macular Degeneration",
            "ConditionCategory": "Medical"
        },
        {
            "ConditionCode": "103",
            "ConditionDescription": "Glaucoma",
            "ConditionCategory": "Medical"
        },
        {
            "ConditionCode": "104",
            "ConditionDescription": "Cataracts",
            "ConditionCategory": "Medical"
        },
        {
            "ConditionCode": "105",
            "ConditionDescription": "Atherosclerosis",
            "ConditionCategory": "Medical"
        },
        {
            "ConditionCode": "106",
            "ConditionDescription": "Chronic Obstructive Pulmonary Disease (COPD)",
            "ConditionCategory": "Medical"
        },
        {
            "ConditionCode": "107",
            "ConditionDescription": "Anemia",
            "ConditionCategory": "Medical"
        },
        {
            "ConditionCode": "108",
            "ConditionDescription": "Depression",
            "ConditionCategory": "Medical"
        },
        {
            "ConditionCode": "109",
            "ConditionDescription": "Cane/Crutches/Walker",
            "ConditionCategory": "Medical"
        },
        {
            "ConditionCode": "110",
            "ConditionDescription": "Anticoagulant Therapy",
            "ConditionCategory": "Medical"
        },
        {
            "ConditionCode": "111",
            "ConditionDescription": "Paraplegic",
            "ConditionCategory": "Medical"
        },
        {
            "ConditionCode": "112",
            "ConditionDescription": "Wheelchair",
            "ConditionCategory": "Medical"
        }
    ],
    "CustomAllergyConditions": [
        {
            "ConditionCode": "100",
            "ConditionDescription": "Shellfish",
            "ConditionCategory": "Allergy"
        }
    ],
    "DispatchTypes": [
        {
            "dispatchTypeName": "Perimeter Check",
            "dispatchTypeID": 1,
            "dispatchCost": 75
        }
    ],
    AddOns: {
        safetyButton: "In-App Personal Safety Button",
        videoAnalytics: "Video Analytics"
    },
    DispatchEventType: {
        PUBLISHED: 1,
        TAKEN: 2,
        ARRIVED: 3,
        FINISHED: 4,
        CANCELLED: 5,
        NOTED: 6,
        CONFIRMED: 7,
        GROUNDING_REQUESTED: 8,
        GROUNDING_REQUEST_CONFIRMED: 9,
        GROUNDING_REQUEST_DECLINED: 10,
        GROUNDING_REQUEST_CONFIRM_ACKED: 11,
        GROUNDING_REQUEST_DECLINE_ACKED: 12,
        GROUNDED: 13,
        GROUNDING_ENDING: 14,
        GROUNDING_END_CONFIRMED: 15,
        GROUNDING_END_DECLINED: 16,
        GROUNDING_END_CONFIRM_ACKED: 17,
        GROUNDING_END_DECLINE_ACKED: 18,
        GROUNDING_TERMINATING: 19,
        GROUNDING_TERMINATE_ACKED: 20,
        GROUNDING_ENDED: 21,
        GROUNDING_UPDATED: 22,
        UNKNOWN: 23
    },
    navHasDispatchBetaConfig: true,
    dispatchFeedbackURL: "https://survey.alchemer.com/s3/7777088/On-Demand-Guard-Services"

}, window.serverConstants);