import * as angular from 'angular';
import 'angular-ui-router';
import { IStateProvider } from 'angular-ui-router';

import breadcrumbsComponent from './breadcrumbs.component';
import breadcrumbsService from './breadcrumbs.service';
import displayOverlayComponent from './featureNotAvailable.component';
import { videoOnlyComponent } from './videoonly.component';
import { jciComponent } from './jci.component';
import { accessCheckComponent } from './accessCheck.component';

export default angular.module('fp:customerportal:common:ui', [
    'ui.router'
])
    .config(/*@ngInject*/
        ($stateProvider: IStateProvider) => {
            $stateProvider
                .state('unavailable',
                    {
                        parent: 'secure',
                        url: '/unavailable',
                        template: '<fp-feature-not-available></fp-feature-not-available>'
                    })
                .state('videoonly',
                    {
                        parent: 'secure',
                        url: '/videoonly',
                        template: '<fp-video-only></fp-video-only>'
                    })
                .state('jci',
                    {
                        parent: 'secure',
                        url: '/jci',
                        template: '<fp-jci></fp-jci>'
                    })
                .state('accessCheck',
                    {
                        parent: 'secure',
                        url: '/accessCheck',
                        template: '<fp-access-check></fp-access-check>'
                    });
        })
    .component('fpBreadcrumbs', breadcrumbsComponent)
    .component('fpFeatureNotAvailable', displayOverlayComponent)
    .component('fpVideoOnly', videoOnlyComponent)
    .component('fpJci', jciComponent)
    .component('fpAccessCheck', accessCheckComponent)
    .service('breadcrumbsService', breadcrumbsService);