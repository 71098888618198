import './accessCheck.scss'

export class AccessCheckController {
    constants: any;
    supportPhone: any;

    constructor(
        constants: any
    ) {
        'ngInject';
        this.constants = constants;
    }

    public $onInit() {
        this.supportPhone = this.constants.customerSupport.orlandoAccountsPhone;
    }
}

export const accessCheckComponent = {
    template: require('./accessCheck.html'),
    controller: AccessCheckController
};