import { IQService, IRootScopeService } from 'angular';
import { IService as IRestangularService } from 'restangular';
import * as _ from 'lodash';
import ApplicationStateDataService from './applicationState.service';


class AccountDataService {

    $rootScope: IRootScopeService;
    Restangular: IRestangularService;
    constants: any;
    applicationStateService: ApplicationStateDataService;
    $q: IQService;

    timeFrame: any;
    BillingAddress: any;
    ShippingAddress: any;
    PremisesAddress: any;
    CustomerInformation: any;

    /*@ngInject*/
    constructor(
        $rootScope: IRootScopeService,
        Restangular: IRestangularService,
        constants: any,
        applicationStateService: ApplicationStateDataService,
        $q: IQService
    ) {
        'ngInject';
        this.$rootScope = $rootScope;
        this.Restangular = Restangular;
        this.constants = constants;
        this.applicationStateService = applicationStateService;
        this.$q = $q;
        this.timeFrame = null;
    }

    getAccountSummary() {
        return this.Restangular
            .all('Account')
            .one('AccountSummary')
            .get()
            .then((result) => {
                this.broadcast(this.constants.events.data.accountSummaryReceived, result);
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    getCustomerInfo() {
        return this.Restangular
            .all('Account')
            .one('CustomerInfo')
            .get()
            .then((result) => {
                this.BillingAddress = _.cloneDeep(result.BillingAddress);
                this.ShippingAddress = _.cloneDeep(result.ShippingAddress);
                this.PremisesAddress = _.cloneDeep(result.PremisesAddress);
                this.CustomerInformation = _.cloneDeep(result.CustomerInformation);
                this.broadcast(this.constants.events.data.customerInfoReceived, result);
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    getCustomerInformationV2() {
        return this.Restangular
            .all('Account')
            .one('CustomerInformationV2')
            .withHttpConfig({ cache: false })
            .get()
            .then((result) => {
                this.BillingAddress = _.cloneDeep(result.BillingAddress);
                this.ShippingAddress = _.cloneDeep(result.ShippingAddress);
                this.PremisesAddress = _.cloneDeep(result.PremisesAddress);
                this.CustomerInformation = _.cloneDeep(result);
                this.broadcast(this.constants.events.data.customerInfoReceived, result);
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    getCustomerAddress(id: string) {
        return this.Restangular
            .all('Account')
            .one('CustomerInfo/address')
            .customGET('',
                {
                   'id': id,
                })
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return this.$q.reject(error);
            });
    }

    isEligibleForVideoAnalytics() {
        return this.Restangular
            .all('Account')
            .one('AccountEligibleForVideoAnalytics')
            .get()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return this.$q.reject(error);
            });
    }

    AccessCheckForOrlandoAccount() {
        return this.Restangular
            .all('Account')
            .one('AccessCheckForOrlandoAccount')
            .get()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return this.$q.reject(error);
            });
    }

    SetAddOnsForVideoAnalytics() {
        return this.Restangular
            .all('addOns')
            .one('set-addOns-video-analytics')
            .customPOST()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return this.$q.reject(error);
            });
    }

    getCustomerInformation() {
        return this.Restangular
            .all('Account')
            .one('CustomerInformation')
            .get()
            .then((result) => {
                this.CustomerInformation = _.cloneDeep(result.CustomerInformation);

                this.broadcast(this.constants.events.data.customerInfoReceived, result);
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    getCustomerInfoSummary() {
        return this.Restangular
            .all('Account')
            .one('customerInfoSummary')
            .get('')
            .then((result) => {
                this.broadcast(this.constants.events.data.customerInfoSummaryReceived, result);
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    getPaymentInfo() {
        return this.Restangular
            .all('Account')
            .one('PaymentInfo')
            .get()
            .then((result) => {
                this.broadcast(this.constants.events.data.paymentInfoReceived, result);
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    updateContactInfo(info: any) {
        return this.Restangular
            .all('Account')
            .one('CustomerInfo')
            .customPUT(info)
            .then((result) => {
                this.applicationStateService.dataChange(
                    this.constants.sectionTypes.accountSettings as string,
                    this.constants.dataTypes.contactInfo as any,
                    this.CustomerInformation,
                    info.CustomerInformation
                );
                this.broadcast(this.constants.events.data.contactInfoUpdated, result);
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    updateAddress(info: any, addressDataTypes: any) {
        return this.Restangular
            .all('Account')
            .one('CustomerInfo')
            .customPUT(info)
            .then((result) => {
                if (_.some(addressDataTypes, (dataType) => { return dataType === this.constants.dataTypes.billingAddress; })) {
                    this.applicationStateService.dataChange(this.constants.sectionTypes.accountSettings, this.constants.dataTypes.billingAddress, this.BillingAddress, info.BillingAddress);
                    this.broadcast(this.constants.events.data.billingAddressUpdated, result);
                }
                if (_.some(addressDataTypes, (dataType) => { return dataType === this.constants.dataTypes.shippingAddress; })) {
                    this.applicationStateService.dataChange(this.constants.sectionTypes.accountSettings, this.constants.dataTypes.shippingAddress, this.ShippingAddress, info.ShippingAddress);
                    this.broadcast(this.constants.events.data.shippingAddressUpdated, result);
                }
                if (_.some(addressDataTypes, (dataType) => { return dataType === this.constants.dataTypes.premisesAddress; })) {
                    this.applicationStateService.dataChange(this.constants.sectionTypes.accountSettings, this.constants.dataTypes.premisesAddress, this.PremisesAddress, info.PremisesAddress);
                    this.broadcast(this.constants.events.data.premisesAddressUpdated, result);
                }

                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    getOrders() {
        return this.Restangular
            .all('Account')
            .one('Orders')
            .get()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    getCouponInfo(couponCode: string) {
        return this.Restangular
            .all('Account')
            .one('Coupons/get-coupon-info')
            .customGET('', {
                'couponCode': couponCode
            })
            .then((result) => {
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    getCouponTotalUsage(couponId: number) {
        return this.Restangular
            .all('Account')
            .one('Coupons/total-usage')
            .customGET('', {
                'couponId' : couponId
            })
            .then((result) => {
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    getContractInfo() {
        return this.Restangular
            .all('Account')
            .one('ContractInfo')
            .get()
            .then((result) => {
                return result.Data;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    getEnrolledPrograms() {
        return this.Restangular
            .all('Account')
            .one('EnrolledPrograms')
            .get()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    pingRapid() {
        return this.Restangular
            .all('Account')
            .one('PingRapid')
            .get();
    }

    getRapidPremise() {
        return this.Restangular
            .all('Account')
            .one('RapidPremise')
            .get();
    }

    getTimeFrame() {
        if (_.isNil(this.timeFrame)) {
            this.timeFrame = this.Restangular
                .all('Account')
                .one('TimeFrame')
                .get();
        }

        return this.timeFrame;
    }

    getAccountCancellationFees(timeFrameId: number) {
        return this.Restangular
            .all('Account')
            .one('CancellationFees')
            .customGET('', {
                'timeFrameId': timeFrameId
            });
    }

    getDispatchTax(dispatchInstances: [any]) {
        return this.Restangular
            .all('Account')
            .one('get-dispatch-tax')
            .customPOST(dispatchInstances)
            .then((result) => {
                return result;
            });
    }

    isAmazonAccount() {
        return this.Restangular
            .all('Account')
            .one('AmazonAccount')
            .get();
    }

    getVoziqOffers() {
        return this.Restangular
            .one('voziq')
            .withHttpConfig({ cache: false })
            .get();
    }

    broadcast(event: any, data: any) {
        return this.$rootScope.$broadcast(event, data);
    }

    sameShippingAndBillingAndPremisesAddress(shippingAddress: any, billingAddress: any, premisesAddress: any) {
        let compareBillingAddress = _.cloneDeep(billingAddress);
        delete compareBillingAddress.Id;

        let compareShippingAddress = _.cloneDeep(shippingAddress);
        delete compareShippingAddress.Id;

        let comparePremisesAddress = _.cloneDeep(premisesAddress);
        delete comparePremisesAddress.Id;

        if (_.isEqual(compareBillingAddress, compareShippingAddress))
            return _.isEqual(comparePremisesAddress, compareShippingAddress);
        else
            return false;
    }
    placeRadioUpgradeOrder = (orderInfo: any) => this.Restangular.all('Account').one('order').customPOST(orderInfo);

    getAccountAddOns() {
        return this.Restangular
            .all('addOns')
            .one('account-addOns')
            .get()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    getAddOns() {
        return this.Restangular
            .one('addOns')
            .get()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    addAccountAddOns(addOnInfo: any) {
        return this.Restangular
            .all('addOns')
            .one('add-account-addOns')
            .customPOST(addOnInfo)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    updateAccountAddOns(addOnInfo: any) {
        return this.Restangular
            .all('addOns')
            .one('update-account-addOns')
            .customPUT(addOnInfo)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }
}

export default AccountDataService;